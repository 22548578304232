import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"

import RootLayout from "../RootLayout"
import Menu from "../../Menu"
import Footer from "../../Footer"
import theme from "../../../theme"

const Layout = ({ children }) => {
  return (
    <RootLayout>
      <Menu />

      {children}

      <Footer />
    </RootLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
