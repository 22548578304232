import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    lineHeight: "2.3rem",
    fontWeight: 600,
    color: "#e6edf0",
    transition: "color 0.5s ease",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}))

const CardLink = ({ link }) => {
  const classes = useStyles()
  return (
    <Link to={link.url} title={link.label} className={classes.root}>
      {link.label}
    </Link>
  )
}

CardLink.defaultProps = {}

export default CardLink
