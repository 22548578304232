import React, { useState } from "react"
import PropTypes from "prop-types"

import clsx from "clsx"

import shortid from "shortid"
import Fab from "@material-ui/core/Fab"
import { useStaticQuery, graphql } from "gatsby"
import { animated, useTransition } from "react-spring"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import { TiArrowRight } from "react-icons/ti"

import useInterval from "../../hooks/useInterval"

const menuItems = ["Home", "Profile", "Order History", "Sign out"]

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    minHeight: "550px",
    position: "relative",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      minHeight: "550px",
    },
  },
  box: {
    backgroundColor: "transparent",
    borderRadius: "3px",

    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      height: "400px",
      transformStyle: "preserve-3d",
      perspective: "2000px",
      transition: ".4s",
      "&::before": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "transparent",
        borderTop: "20px solid " + theme.palette.primary.main,
        borderLeft: "20px solid " + theme.palette.primary.main,
        boxSizing: "border-box",
        borderRadius: "6px",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        borderBottom: "20px solid " + theme.palette.primary.main,
        borderRight: "20px solid " + theme.palette.primary.main,
        boxSizing: "border-box",
        borderRadius: "6px",
      },
    },
  },
  icon: {
    fontSize: "25px",
    height: "50px",
    width: "50px",
    lineHeight: "50px !important",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    borderRadius: "6px",
  },
  iconRight: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      position: "absolute",
      bottom: "0",
      right: "0",
      zIndex: "1",
    },
  },
  iconLeft: { position: "absolute", top: "0", left: "0" },
  text: {
    backgroundColor: "#fff",
    borderRadius: "6px",
    transition: ".4s",
    //boxShadow: theme.shadows[2],
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "30px",
      left: "-30px",
      width: "calc(100% + 60px)",
      height: "calc(100% - 60px)",
    },
  },
  wrapper: {
    textAlign: "center",
    width: "100%",
    padding: "30px 60px",
    lineHeight: "1.5",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "50%",
      left: "0",
      transform: "translateY(-50%)",
    },
  },
  item: {
    willChange: "transform, opacity",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
  },
  customer: {
    marginTop: theme.spacing(3),
    fontWeight: 800,
  },
  buttonContainer: {
    padding: 15,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    fontSize: "3rem",
    boxShadow: "none",
  },
}))

const Testimonials = ({ testimonials }) => {
  const classes = useStyles()
  const [index, setIndex] = useState(0)

  const transitions = useTransition(index, null, {
    from: { opacity: 0, transform: "translate3d(100%, 0 ,0)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-50%, 0, 0)" },
  })

  const handleNext = () => {
    setIndex(index === testimonials.length - 1 ? 0 : index + 1)
  }

  useInterval(() => {
    // Your custom logic here
    handleNext()
  }, 10000)

  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "bg_testimonials.png" }) {
        childImageSharp {
          fluid(maxWidth: 524) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {transitions.map(({ item, props, key }) => {
          return (
            item !== undefined && (
              <animated.div
                key={testimonials[item].key}
                style={props}
                className={classes.item}
              >
                <div className={classes.box}>
                  <div className={clsx(classes.icon, classes.iconRight)}>
                    <FaQuoteRight />
                  </div>
                  <div className={classes.text}>
                    <div className={clsx(classes.icon, classes.iconLeft)}>
                      <FaQuoteLeft />
                    </div>
                    <div className={classes.wrapper}>
                      <Typography color="textSecondary" gutterBottom>
                        {testimonials[item].text}
                      </Typography>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        className={classes.customer}
                      >
                        {testimonials[item].customer}
                      </Typography>
                    </div>
                  </div>
                </div>
              </animated.div>
            )
          )
        })}
      </div>
      <div className={classes.buttonContainer}>
        <Fab onClick={handleNext} size="large" className={classes.button}>
          <TiArrowRight />
        </Fab>
      </div>
    </div>
  )
}

Testimonials.defaultProps = {
  testimonials: [
    {
      key: shortid.generate(),
      customer: "Caroline J., maman d’élève en 4ème humanité",
      text:
        "Pour nous, tu es professeur (voir tuteur) qui donne du sens aux études de l’enfant et énormément de motivation. Tu es la personne avec qui Cyril apprécie d’étudier.",
    },
    {
      key: shortid.generate(),
      customer: "Charles de F., 3è BAC Droit ULB, réorientation scolaire",
      text:
        "Étant quelque peu réticent à me confier au début, Laure a su trouver la patience et l’approche pour me mettre en confiance. C’est vraiment une des plus belles rencontres que j’ai faites. Sans son aide je ne serais pas là où je suis arrivé. Elle a su me donner le courage de finir un projet long et ennuyeux dans lequel je m’enlisais mais qui, aujourd’hui, m’ouvre des portes. Encore un grand merci à toi Laure.",
    },
    {
      key: shortid.generate(),
      customer: "Mathilde C., 1è BAC Economie ICHEC ",
      text:
        "Étant une étudiante vivant seule, les distractions dans mon appartement sont grandes. Par ce mini blocus dans un environnement adapté au travail et grâce à l'aide de monsieur de Merode, j'ai pu pendant toute la semaine me concentrer, mettre en œuvre un planning de travail efficace et réussir au mieux mon blocus.",
    },
    {
      key: shortid.generate(),
      customer: "Isabelle D., maman d’élève en 1è BAC Bio-ingénieur UCL",
      text:
        "Mon fils démotivé suite à de nombreux stages collectifs d’étude ou de techniques d’apprentissage sans résultats concrets est revenu enchanté de sa première rencontre avec Monsieur de Merode. En deux heures la confiance et la motivation sont revenues. Merci !",
    },
    {
      key: shortid.generate(),
      customer: "Guillaume E., 1è BAC Economie ICHEC",
      text:
        "Monsieur de Merode m’aide depuis plus d’un an maintenant, et il m’a vraiment aidé. Il a toujours cru en moi et me poussait pour que je donne le meilleur de moi-même. Grâce à Monsieur, j’ai réussi là ou beaucoup pensaient me voir échouer.",
    },
    {
      key: shortid.generate(),
      customer: "Jeremy B., 1è BAC Bio-ingénieur UCL, réorientation scolaire",
      text:
        "Laure m’a permis, en 3-4 sessions, de vraiment me recentrer sur les points importants que je cherche dans mon futur métier et les études qui allaient avec, et ce grâce à quelques tests et des questions précises. Elle a traité ma situation sous différentes approches pour que je puisse me découvrir sous des angles que je n’aurais peut-être pas vu par moi-même. J’ai maintenant trouvé des études qui me conviennent très bien !",
    },
    {
      key: shortid.generate(),
      customer: "Geoffroy H., 1è BAC Economie ICHEC",
      text:
        "Étant hyperactif, travailler avec quelqu’un me permet d’augmenter ma concentration. Travailler avec Monsieur de Merode permet aussi de ne pas se décourager, de mieux comprendre et surtout il nous donne les bonnes méthodes pour être le plus efficace possible.",
    },
    {
      key: shortid.generate(),
      customer: "Maisan B., 1è BAC Psychologie Marie-Haps",
      text:
        "Super expérience, je me sens motivée et déterminée à réussir. Monsieur de Merode est polyvalent dans de nombreux domaines. Nous travaillons dans une chouette ambiance et les produits alimentaires sont de qualité !",
    },
    {
      key: shortid.generate(),
      customer: "Marie S., 1è BAC Bio-ingénieur UCL",
      text:
        "Merci beaucoup pour cette semaine, un bon démarrage pour un premier blocus. Un peu du mal parfois mais c’est pour mon bien que vous faites ça 😊",
    },
    {
      key: shortid.generate(),
      customer:
        "Christophe P., jeune travailleur, réorientation professionnelle",
      text:
        "Cela fait plus d’un an maintenant et je pense encore souvent à ton aide qui m’a été très bénéfique. Après avoir essayé quelques idées, suivi certaines formations, participé à un WE startup ainsi que porté un projet lors d’une session challenge, je n’ai toutefois pas encore lancé formellement mon activité. Quoi qu’il en soit, je change de boulot le mois prochain. Je voulais te remercier pour tes conseils et ton aide qui m’ont permis d’avancer.",
    },
  ],
}

export default Testimonials
