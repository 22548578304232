import React, { useRef, useState, useCallback } from "react"
import { animated, useSpring, useTrail, useChain } from "react-spring"
import clsx from "clsx"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import chroma from "chroma-js"
import { useStaticQuery, graphql } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import useHover from "../../hooks/useHover"

import CardLink from "./CardLink"
const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "100%",
    paddingBottom: "100%",
    borderRadius: "10px",
    overflow: "hidden",
  },
  content: {
    color: "#fff",
    position: "absolute",
    borderRadius: "10px",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    zIndex: 2,
  },
  contentHover: {
    zIndex: 3,
    backgroundColor: chroma(theme.palette.primary.main)
      .alpha(0.8)
      .css(),
  },
  background: {
    position: "absolute !important",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    overflow: "hidden",
  },
  title: {
    position: "relative",
    textTransform: "uppercase",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 800,
  },
  subtitle: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  line: {
    position: "absolute",
    height: "6px",
    bottom: -10,
    borderRadius: "2px",
    backgroundColor: theme.palette.secondary.main,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
  },
  links: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
}))

const Card = ({ title, color, links, name }) => {
  const classes = useStyles()
  const [isToggle, setIsToggle] = useState(false)
  const [hoverRef, isHovered] = useHover()
  const isMobile = useMediaQuery("(max-width:600px)")

  const toggle = isMobile ? isToggle : isHovered

  const handleClickToggle = useCallback(() => {
    setIsToggle(!isToggle)
  })
  const handleClickAway = useCallback(() => {
    setIsToggle(false)
  })

  const subtitleColor = chroma(color)
    .brighten(2.5)
    .css()

  const springContentStyle = useSpring({
    backgroundColor: chroma(color)
      .alpha(0.8)
      .css(),
    opacity: toggle ? 0 : 1,
  })

  const springContentHoverStyle = useSpring({
    opacity: toggle ? 1 : 0,
  })

  const springTitleRef = useRef()
  const springTitleStyle = useSpring({
    opacity: toggle ? 1 : 0,

    transform: toggle ? "translate3d(0%,0,0)" : "translate3d(-100%,0,0)",
    ref: springTitleRef,
  })

  const springLineRef = useRef()
  const springLineStyle = useSpring({
    width: toggle ? "70px" : "0px",
    ref: springLineRef,
  })

  useChain(
    toggle ? [springTitleRef, springLineRef] : [springLineRef, springTitleRef],
    [0.1, 0.3]
  )

  const trail = useTrail(links.length, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })

  const data = useStaticQuery(graphql`
    query {
      primaire: file(relativePath: { eq: "bg_primaire.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondaire: file(relativePath: { eq: "bg_secondaire.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      superieur: file(relativePath: { eq: "bg_superieur.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.root}
        ref={!isMobile ? hoverRef : undefined}
        onClick={isMobile ? handleClickToggle : undefined}
      >
        <animated.div
          style={springContentHoverStyle}
          className={clsx(classes.content, classes.contentHover)}
        >
          <animated.div style={springTitleStyle}>
            <Typography variant="h6" component="span" className={classes.title}>
              {title}
              <animated.div style={springLineStyle} className={classes.line} />
            </Typography>
          </animated.div>
          <div
            className={classes.links}
            style={{ pointerEvents: toggle ? "auto" : "none" }}
          >
            {trail.map(({ x, height, ...rest }, index) => (
              <animated.div
                key={links[index].name}
                style={{
                  ...rest,
                  transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                }}
              >
                <CardLink link={links[index]} />
              </animated.div>
            ))}
          </div>
        </animated.div>
        <animated.div style={springContentStyle} className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>

          {isMobile && (
            <Typography
              variant="subtitle2"
              className={classes.subtitle}
              style={{ color: subtitleColor }}
            >
              En savoir plus
            </Typography>
          )}
        </animated.div>
        <Img
          fluid={data[name].childImageSharp.fluid}
          className={classes.background}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </div>
    </ClickAwayListener>
  )
}

Card.defaultProps = {
  title: "Primaire",
  color: chroma("#1DADFF")
    .alpha(0.8)
    .css(),
  name: "primaire",
}

export default Card
