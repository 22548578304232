import React, { useState } from "react"
import clsx from "clsx"
import { useFormik } from "formik"
import chroma from "chroma-js"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import swal from "sweetalert2"
import { cover } from "polished"
import { makeStyles } from "@material-ui/core/styles"
import red from "@material-ui/core/colors/red"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { sendEmail } from "../../config/email"
import MarkdownContent from "../MarkdownContent"
const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    color: theme.palette.primary.main,
    position: "relative",
    width: "100%",
    overflow: "hidden",
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-y",
    backgroundSize: "cover",
    borderRadius: "20px",
    borderTop: "solid 8px " + theme.palette.secondary.main,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  cover: {
    ...cover(),
    backgroundColor: "#25353C",
    opacity: 0.8,
    zIndex: -1,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  contenu: {
    fontWeight: 700,
    color: "#fff",
    fontFamily: "Kalam",
  },
  contenuContainer: {
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3),
  },
  button: {
    boxShadow: "none",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  svg: {
    width: "50%",
    top: "-150px",
    left: "-150px",
    transform: "rotate(-25deg)",
    position: "absolute",
    zIndex: 0,
  },
}))

const Banner = ({ testimonials }) => {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      bloc: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(elements)/Bandeau.md$/" } }
      ) {
        edges {
          node {
            id
            frontmatter {
              content
              label
              url
            }
          }
        }
      }

      backgroundImage: file(relativePath: { eq: "bg_contact.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const content = data.bloc.edges[0].node.frontmatter

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 63 62"
        className={classes.svg}
      >
        <defs>
          <linearGradient
            id="Gradient_bez_nazwy_10"
            x1="7"
            y1="33"
            x2="45"
            y2="33"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#ffc20e" />
            <stop offset="0.23" stop-color="#ffbf0e" />
            <stop offset="0.41" stop-color="#ffb50e" />
            <stop offset="0.58" stop-color="#ffa50e" />
            <stop offset="0.73" stop-color="#ff8d0e" />
            <stop offset="0.88" stop-color="#ff6f0e" />
            <stop offset="1" stop-color="#ff500e" />
          </linearGradient>
        </defs>
        <title>multilingual</title>
        <g id="Warstwa_2" data-name="Warstwa 2">
          <g id="Proximo">
            <polygon class="cls-1" points="45 51 7 37.5 7 28.5 45 15 45 51" />
            <path
              class="cls-2"
              d="M51,54h0a3,3,0,0,1-3-3V9a3,3,0,0,1,3-3h0a3,3,0,0,1,3,3V51A3,3,0,0,1,51,54Z"
            />
            <polygon class="cls-2" points="6 37 1 36 1 24 6 23 6 37" />
            <path
              class="cls-2"
              d="M17.44,39.09l3.12,10.19A3.65,3.65,0,1,1,13.47,51L11,36.79"
            />
            <polyline class="cls-2" points="48 50 6 35 6 25 48 10" />
            <polyline
              class="cls-2"
              points="35.84 19.02 33.04 20.02 29.65 21.23 28.15 21.77"
            />
            <line class="cls-2" x1="39.81" y1="17.6" x2="39.12" y2="17.85" />
            <line class="cls-2" x1="10" y1="28" x2="10" y2="23.57" />
            <line class="cls-2" x1="14" y1="28" x2="14" y2="22.14" />
            <line class="cls-2" x1="18" y1="28" x2="18" y2="20.71" />
            <line class="cls-2" x1="58" y1="30" x2="62" y2="30" />
            <line class="cls-2" x1="58" y1="24" x2="62" y2="23" />
            <line class="cls-2" x1="58" y1="36" x2="62" y2="37" />
            <line class="cls-2" x1="58" y1="18" x2="62" y2="16" />
            <line class="cls-2" x1="58" y1="42" x2="62" y2="44" />
            <line class="cls-2" x1="58" y1="2" x2="58" y2="4" />
            <line class="cls-2" x1="59" y1="3" x2="57" y2="3" />
            <line class="cls-2" x1="56" y1="59" x2="56" y2="61" />
            <line class="cls-2" x1="57" y1="60" x2="55" y2="60" />
            <line class="cls-2" x1="62" y1="53" x2="62" y2="53" />
            <line class="cls-2" x1="61" y1="10" x2="61" y2="10" />
            <line class="cls-2" x1="51" y1="1" x2="51" y2="1" />
            <line class="cls-2" x1="40" y1="53" x2="40" y2="55" />
            <line class="cls-2" x1="41" y1="54" x2="39" y2="54" />
            <line class="cls-2" x1="46" y1="59" x2="46" y2="59" />
            <line class="cls-2" x1="44" y1="6" x2="44" y2="6" />
            <line class="cls-2" x1="39" y1="2" x2="39" y2="2" />
          </g>
        </g>
      </svg>

      <BackgroundImage
        className={classes.root}
        Tag="div"
        fluid={data.backgroundImage.childImageSharp.fluid}
      >
        <div className={classes.cover}></div>
        <Container className={classes.container}>
          <div className={classes.contenuContainer}>
            <Typography variant="h4" component="h2" className={classes.contenu}>
              {content.content}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                title={content.label}
                href={content.url}
                target="_blank"
                rel="noreferrer"
                variant="contained"
                color="secondary"
                size="large"
                className={classes.button}
              >
                {content.label}
              </Button>
            </div>
          </div>
        </Container>
      </BackgroundImage>
    </div>
  )
}

Banner.defaultProps = {}

export default Banner
