import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import MaterialButton from "@material-ui/core/Button"
import { Element, scroller } from "react-scroll"
import { makeStyles } from "@material-ui/core/styles"

import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Hero from "../components/Hero"
import Section from "../components/Section"
import Heading from "../components/Heading"
import Card from "../components/Card"
import Banner from "../components/Banner"
import Testimonials from "../components/Testimonials"
import Button from "../components/Button"
import Contact from "../components/Contact"
import SEO from "../components/SEO"

import links from "../locales/fr/links"

const OFFSET = -100

const useStyles = makeStyles(theme => ({
  heroButton: {
    padding: 12,
    minWidth: 250,
    fontWeight: 600,
    margin: "30px 10px",
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0px",
    },
  },
  subtitle: {
    fontSize: "2rem",
    position: "relative",
    fontFamily: "'Kalam', cursive",
    textAlign: "center",
  },
  image: {
    borderRadius: "20px",
  },
  caption: {
    marginTop: theme.spacing(3),
    fontSize: "small",
    display: "block",
    textAlign: "center",
  },
}))

const IndexPage = ({ children }) => {
  const classes = useStyles()

  useEffect(() => {
    let hash = window.location.hash
    hash = hash.replace("#", "")

    if (hash && hash != "") {
      scroller.scrollTo(hash, {
        smooth: true,
        offset: OFFSET,
      })
    }
  }, [])

  const handleScrollToContact = useCallback(() => {
    scroller.scrollTo("contact", {
      smooth: true,
      offset: OFFSET,
    })
  })

  const handleScrollToSolutions = useCallback(() => {
    scroller.scrollTo("nos-solutions", {
      smooth: true,
      offset: OFFSET,
    })
  })

  const data = useStaticQuery(graphql`
    query {
      thierry: file(relativePath: { eq: "photo_thierry_nb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <SEO title="Accueil" />
      <Element name="accueil">
        <Hero
          title="Ensemble donnons du sens à vos études"
          subtitle="Eureka propose des accompagnements personnalisés aux étudiants pour réussir et s’épanouir dans leurs études"
          minHeight="80vh"
        >
          <div>
            <MaterialButton
              onClick={handleScrollToContact}
              color="secondary"
              variant="contained"
              className={classes.heroButton}
            >
              Nous contacter
            </MaterialButton>
            <MaterialButton
              onClick={handleScrollToSolutions}
              color="default"
              variant="contained"
              className={classes.heroButton}
            >
              En savoir plus
            </MaterialButton>
          </div>
        </Hero>
      </Element>
      {/*
    <Element name="notre-ecole">
        <Section>
          <Heading
            title="Notre école"
            subtitle="Eureka propose des accompagnements personnalisés aux étudiants pour réussir et s’épanouir dans ses études"
          />
          <p>
            Eureka propose des accompagnements personnalisés aux étudiants pour
            réussir et s’épanouir dans ses études.
          </p>
          <p>
            Eureka a été fondé par Thierry de Merode, docteur en sciences, et
            s'est développé en une équipe multidisciplinaire spécialisée dans
            l’enseignement et le coaching scolaire et d’orientation.
          </p>
          <p>
            Parce que chaque étudiant et chaque situation sont différents, nous
            concevons toujours une approche sur mesure : du coaching scolaire
            aux cours particuliers en passant par des blocus et de l’orientation
            scolaire.
          </p>
        </Section>
      </Element>

      <Section>
        <p className={classes.subtitle}>
          Parce que chaque étudiant et chaque situation sont différents, nous
          concevons toujours une approche sur mesure: du coaching scolaire aux
          cours particuliers en passant par des blocus et de l'orientation
          scolaire.
        </p>
      </Section>
            */}
      <Element name="nos-solutions">
        <Section variant="secondary">
          <Heading
            title="Nos solutions"
            subtitle={
              <React.Fragment>
                Parce que chaque étudiant et chaque situation sont différents,
                nous concevons toujours une approche sur mesure: du{" "}
                <Link to="/coaching-scolaire">
                  <strong
                    style={{
                      textDecorationStyle: "dotted",
                      textDecorationLine: "underline",
                    }}
                  >
                    coaching scolaire
                  </strong>
                </Link>{" "}
                aux{" "}
                <Link to="/cours-particuliers">
                  <strong
                    style={{
                      textDecorationStyle: "dotted",
                      textDecorationLine: "underline",
                    }}
                  >
                    cours particuliers
                  </strong>
                </Link>{" "}
                en passant par des{" "}
                <Link to="/blocus-assiste">
                  <strong
                    style={{
                      textDecorationStyle: "dotted",
                      textDecorationLine: "underline",
                    }}
                  >
                    blocus assistés
                  </strong>
                </Link>{" "}
                et de l'
                <Link to="/orientation-scolaire">
                  <strong
                    style={{
                      textDecorationStyle: "dotted",
                      textDecorationLine: "underline",
                    }}
                  >
                    orientation scolaire
                  </strong>
                </Link>
                .
              </React.Fragment>
            }
          />
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Card
                color="#1DADFF"
                title="Primaire"
                name="primaire"
                links={links.cards.primaire}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                color="#3CAECC"
                title="Secondaire"
                name="secondaire"
                links={links.cards.secondaire}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                color="#F3728B"
                title="Supérieur"
                name="superieur"
                links={links.cards.superieur}
              />
            </Grid>
            <Grid item container justify="center">
              <div style={{ margin: "2em 0" }}>
                <Button color="primary" onClick={handleScrollToContact}>
                  Contactez-nous
                </Button>
              </div>
            </Grid>
          </Grid>
        </Section>
      </Element>
      <Section>
        <Banner />
      </Section>
      <Element name="notre-adn">
        <Section>
          <Heading
            title="Notre ADN"
            subtitle="Comprendre, Apprendre & Réussir"
          />
          <Grid container spacing={8}>
            <Grid item md={4} container justify="center">
              <div>
                <Img
                  fluid={data.thierry.childImageSharp.fluid}
                  className={classes.image}
                />
                <figcaption className={classes.caption}>
                  Thierry de Merode, fondateur & tuteur
                </figcaption>
              </div>
            </Grid>
            <Grid item md={8}>
              <p style={{ marginTop: 0 }}>
                Notre nom, <strong>Eureka</strong>, est au cœur de notre
                philosophie.
              </p>
              <p>
                Nous savons qu'une éducation réussie permet aux jeunes de
                devenir des adultes heureux et confiants. Nous sommes convaincus
                que la réussite et l’épanouissement passent par le fait de
                donner du <strong>sens à ses études</strong>.
              </p>
              <p>
                D’abord grâce à une bonne <strong>orientation</strong> où le
                jeune devient <strong>acteur de son projet de vie</strong>.
                Ensuite grâce à une <strong>compréhension</strong> de la matière
                qui permet l’
                <strong>ancrage</strong> profond et suscite l’
                <strong>intérêt</strong>.
              </p>
              <p>
                Notre méthode de travail associe une implication active de
                l’étudiant à un soutien scolaire et moral.
              </p>
              <p>
                Nous faisons toujours cela avec un objectif clair :{" "}
                <strong>faire réussir et s’épanouir les étudiants.</strong>
              </p>

              <div style={{ margin: "2em 0" }}>
                <Link to="/qui-sommes-nous">
                  <Button color="secondary">En savoir plus sur Eureka</Button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Section>
      </Element>
      <Element name="temoignages">
        <Section variant="secondary">
          <Heading
            title="Témoignages"
            subtitle="Découvrez les témoignages des étudiants et de leurs parents qui nous font confiance"
          />
          <Testimonials />
        </Section>
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
    </React.Fragment>
  )
}

IndexPage.propTypes = {
  children: PropTypes.node,
}

export default IndexPage
