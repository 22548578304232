import React from "react"
import { Link } from "gatsby"

import MainLayout from "../components/layouts/MainLayout"
import IndexPage from "../containers/IndexPage"

const SecondPage = () => {
  // or const [t, i18n] = useTranslation();

  return (
    <MainLayout>
      <IndexPage />
    </MainLayout>
  )
}

export default SecondPage
